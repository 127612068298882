import React from "react"
import { decode } from "html-entities"

import type { PageProps } from "gatsby"

import Box from "@material-ui/core/Box"
import Container from "@ecom/ui/components/Container"
import PageStatus from "@ecom/ui/components/PageStatus"
import SecondaryPageLayout from "../components/Layouts/secondaryPage"

type State = {
  onScreenTitle?: string
  onScreenDescription?: string
}

const Content = (html: { __html: string }) => (
  <div style={{ padding: "38px 0 18px", fontSize: 16 }}>
    <div dangerouslySetInnerHTML={html} />
  </div>
)

export default function ErrorPage({ location }: PageProps<object, object, State>) {
  const { onScreenTitle, onScreenDescription } = location?.state || {}

  const html = { __html: decode(onScreenDescription) }

  return (
    <SecondaryPageLayout title="Ошибка">
      <section className="form-outer">
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <PageStatus
              head={onScreenTitle}
              content={onScreenDescription ? Content(html) : undefined}
            />
          </Box>
        </Container>
      </section>
    </SecondaryPageLayout>
  )
}
